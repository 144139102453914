import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "index" }

import PIndex from "./pc/index/index.vue"
import MIndex from "./mobile/index/index.vue"
import Config from "@/data/config"
import useConfigStore from "@/store/config";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const configStore = useConfigStore()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(configStore).isPC)
      ? (_openBlock(), _createBlock(PIndex, { key: 0 }))
      : (_openBlock(), _createBlock(MIndex, { key: 1 }))
  ]))
}
}

})