import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "pmod" }
const _hoisted_2 = { style: {"min-height":"916px","padding-top":"50px","padding-bottom":"30px"} }
const _hoisted_3 = { class: "pmod-c1" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "pmod-c2" }
const _hoisted_6 = { class: "pmod-c2-t1" }
const _hoisted_7 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_8 = { class: "pmod-c2-c1-t1" }
const _hoisted_9 = { style: {"margin-top":"14px","display":"inline-block","line-height":"36px","font-size":"18px"} }

import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const activeOption = ref(0)
const options = ref(["mod.1", "mod.2"])
const textList = ref([
    [
        { title: "mod.4", text: "mod.5" },
        { title: "mod.6", text: "mod.7" },
        { title: "mod.8", text: "mod.9" },
        { title: "mod.10", text: "mod.11" },
    ],
    [
        { title: "mod.12", text: "mod.13" },
        { title: "mod.14", text: "mod.15" },
        { title: "mod.16", text: "mod.17" },

    ]
])
function clickOption(index: number) {
    activeOption.value = index
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "pmod-menuItem",
            key: index,
            onClick: ($event: any) => (clickOption(index)),
            style: _normalizeStyle({ color: index === activeOption.value ? '#01FEF0' : '#DEDEDE' })
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t(item)) + " ", 1),
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass('pmod-menuItem-Bar pmod-menuItem-Bar-' + index)
            }, null, 2), [
              [_vShow, index == activeOption.value]
            ])
          ], 12, _hoisted_4))
        }), 128)),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "pmod-line" }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("mod.3")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(textList.value[activeOption.value], (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "pmod-c2-c1",
              key: index
            }, [
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "pmod-c2-c1-line" }, null, -1)),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(item.title)), 1)
              ]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t(item.text)), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "pmod-line1" }, null, -1))
  ]))
}
}

})