import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "friends" }

import PFriends from "./pc/friends/index.vue"
import MFriends from "./mobile/friends/index.vue"
import useConfigStore from "@/store/config";

export default /*@__PURE__*/_defineComponent({
  __name: 'friends',
  setup(__props) {

const configStore = useConfigStore()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(configStore).isPC)
      ? (_openBlock(), _createBlock(PFriends, { key: 0 }))
      : (_openBlock(), _createBlock(MFriends, { key: 1 }))
  ]))
}
}

})