import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/topBar/logo.png'
import _imports_1 from './img/h5_icon_language@2x.png'
import _imports_2 from './img/pc_icon_pull@2x.png'
import _imports_3 from './img/pc_icon_pull-1.png'


const _hoisted_1 = { class: "topBar" }
const _hoisted_2 = { style: {"margin-right":"360px","display":"inline-flex","align-items":"center"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"display":"inline-block","margin":"0px 8px"} }
const _hoisted_5 = {
  key: 0,
  src: _imports_2,
  style: {"width":"10px","height":"7px"}
}
const _hoisted_6 = {
  key: 1,
  src: _imports_3,
  style: {"width":"10px","height":"7px"}
}

import Config from "@/data/config";
import i18n from "@/i18n";
import useConfigStore from "@/store/config";
import { useLangStore } from "@/store/lang";
import { ref } from "vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const langStore = useLangStore()
const configStore = useConfigStore()
const menuList = ref([
    { name: "topbar.1", path: "/", key: "1" },
    { name: "topbar.2", path: "/mod", key: "2" },
    { name: "topbar.3", path: "/friends", key: "3" },
    { name: "topbar.4", path: "/about", key: "4" },
])
const chooseLang = ref(false)
function selectLang(index: number) {
    langStore.nowLang = index
    i18n.global.locale.value = langStore.langList[index].code
    window.localStorage.setItem("lang", langStore.langList[index].code)
    chooseLang.value = false
}
function menuClick(key: string, path: string) {
    configStore.topMenuActive = key
    Config.Router.push(path)

}
function setBarPosition() {
    let div = document.getElementById("menuBar")
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("img", {
      src: _imports_0,
      style: {"width":"113px","height":"20px","margin-left":"360px"},
      class: "topBar-img"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "topBar-menuItem",
            key: index,
            onClick: ($event: any) => (menuClick(item.key, item.path)),
            style: _normalizeStyle({ color: item.key === _unref(configStore).topMenuActive ? '#01FEF0' : '#DEDEDE' })
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t(item.name)) + " ", 1),
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass('topBar-menuItem-Bar topBar-menuItem-Bar-' + index)
            }, null, 2), [
              [_vShow, item.key == _unref(configStore).topMenuActive]
            ])
          ], 12, _hoisted_3))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: "topBar-langBtn",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (chooseLang.value = !chooseLang.value))
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("img", {
          src: _imports_1,
          style: {"width":"14px","height":"14px"}
        }, null, -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(langStore).langList[_unref(langStore).nowLang].name), 1),
        (chooseLang.value)
          ? (_openBlock(), _createElementBlock("img", _hoisted_5))
          : (_openBlock(), _createElementBlock("img", _hoisted_6)),
        _withDirectives(_createElementVNode("div", {
          class: "topBar-langDrop",
          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("span", {
            style: {"margin-top":"22px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (selectLang(0)))
          }, "繁体中文"),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "topBar-line" }, null, -1)),
          _createElementVNode("span", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (selectLang(1)))
          }, "简体中文"),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "topBar-line" }, null, -1)),
          _createElementVNode("span", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (selectLang(2)))
          }, "English")
        ], 512), [
          [_vShow, chooseLang.value]
        ])
      ])
    ])
  ]))
}
}

})