<template>
    <div class="bottomBar">
        <div class="bottomBar-c1">
            <span>{{$t("index.14")}}:</span>
            <img src="./img/home_icon_binance.png" width="24" height="24" style="margin-left: 20px;" />
            <img src="./img/home_icon_okx.png" width="24" height="24" style="margin-left: 20px;" />
            <img src="./img/home_icon_bitget.png" width="24" height="24" style="margin-left: 20px;" />
            <img src="./img/home_icon_gate.png" width="24" height="24" style="margin-left: 20px;" />
            <img src="./img/home_icon_huobi.png" width="24" height="24" style="margin-left: 20px;" />
        </div>
        <div class="bottomBar-c2">
            Copyright © 2024, google.com
        </div>
    </div>
</template>
<style scoped>
@import url("./index.css");
</style>