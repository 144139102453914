<template>
    <div class="mindex-card">
        <img :src="props.src" class="mindex-card-img1" />
        <div class="mindex-card-t1">{{ props.title }}</div>
        <div class="mindex-card-t2">{{ props.t1 }}<span>{{ props.p }}</span></div>
        <img src="../img/h5_icon_more.png" class="mindex-card-img2" @click="clickEvent" />
        <div class="mindex-card-c1">
            <div class="mindex-card-c1-bg"></div>
            <div class="mindex-card-t3">{{ props.t3 }}</div>
            <div class="mindex-card-t4">{{ props.t2 + " USDT" }}</div>
            <img src="../img/h5_img_spot_graph.png" class="mindex-card-img3" />

        </div>
    </div>
</template>
<script lang="ts" setup>
import Config from "@/data/config";
import { defineProps } from "vue"
const props = defineProps({
    src: { type: String, default: () => { return require("../img/h5_icon_spot.png") } },
    title: { type: String, default: "现货套利机器人" },
    t1: { type: String, default: "月收益8%, 复利年收益250%+" },
    t2: { type: String, default: "123,125.25 usdt" },
    t3: { type: String, default: "累计套利" },
    p: { type: String, default: "250%+" },
    index: { type: Number, default: 0 }

})
function clickEvent() {
    Config.Router.push({
        path: "/mod",
        query: { mod: props.index }
    })
}
</script>
<style scoped>
.mindex-card {
    width: 670px;
    /* height: 382px; */
    background: #202125;
    border-radius: 30px;
    border: 1px solid #333333;
    position: relative;
    margin: auto;
    padding-bottom: 44px;
    margin-bottom: 50px;
    text-align: left;
}

.mindex-card-img1 {
    position: absolute;
    width: 77px;
    height: 80px;
    left: 34px;
    top: 56px;
}

.mindex-card-t1 {
    position: relative;
    display: inline-block;
    font-size: 28px;
    color: #FFFFFF;
    font-family: "medium";
    margin-left: 141px;
    margin-top: 50px;
}

.mindex-card-t2 {
    position: relative;
    display: inline-block;
    font-size: 24px;
    line-height: 34px;
    color: #909090;
    text-align: left;
    font-family: "medium";
    margin-left: 141px;
    margin-top: 11px;
}

.mindex-card-t2>span {
    color: #01FEF0;
    font-family: "medium";
    display: inline-block;
    margin-left: 5px;
}

.mindex-card-img2 {
    position: absolute;
    width: 26px;
    height: 24px;
    right: 28px;
    top: 59px;
}

.mindex-card-c1-bg {
    width: 610px;
    height: 150px;
    background: #292A2E;
    border-radius: 22px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.mindex-card-c1 {
    width: 610px;
    height: 150px;
    position: relative;
    left: 30px;
    margin-top: 52px;
}

.mindex-card-t3 {
    position: absolute;
    left: 40px;
    top: 30px;
    font-family: "medium";
    font-size: 24px;
    color: rgba(255, 255, 255, 0.5);
}

.mindex-card-t4 {
    position: absolute;
    left: 40px;
    top: 75px;
    font-family: "number";
    font-size: 34px;
    color: #D4D4D5;
}

.mindex-card-img3 {
    position: absolute;
    width: 133px;
    height: 85px;
    right: 36px;
    top: 42px;
}
</style>