<template>
    <div class="pfriends">
        <div style="height: 916px;padding-top: 50px;">
            <div class="pfriends-c1">
                <div class="pfriends-title">{{ $t("friends.1") }}</div>
                <div class="pfriends-t1">{{ $t("friends.2") }}</div>
                <div class="pfriends-c2-c1" v-for="(item, index) in textList" :key="index">
                    <div style="display: flex;align-items: center;">
                        <div class="pfriends-c2-c1-line"></div><span class="pfriends-c2-c1-t1">{{ $t(item.title) }}</span>
                    </div>
                    <div style="margin-top: 14px;display: inline-block;line-height: 32px;" v-html="Config.turnStar($t(item.text))"></div>
                </div>
                <div class="pfriends-t2">{{$t("friends.7")}}</div>
            </div>
        </div>
        <div class="pfriends-line1">
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import Config from '@/data/config';
const textList = ref(
    [
        { title: "friends.3", text: "friends.4" },
        { title: "friends.5", text: "friends.6" },
    ]
)
</script>
<style scoped>
@import url("./index.css");
</style>