<template>
    <div class="pmod">
        <div style="min-height: 916px;padding-top: 50px;padding-bottom: 30px;">
            <div class="pmod-c1">
                <div class="pmod-menuItem" v-for="(item, index) in options" :key="index" @click="clickOption(index)"
                    :style="{ color: index === activeOption ? '#01FEF0' : '#DEDEDE' }">
                    {{ $t(item) }}
                    <div :class="'pmod-menuItem-Bar pmod-menuItem-Bar-' + index" v-show="index == activeOption">
                    </div>
                </div>
                <div class="pmod-line"></div>
            </div>
            <div class="pmod-c2">
                <div>
                    <div class="pmod-c2-t1">
                        {{ $t("mod.3") }}
                    </div>
                    <div class="pmod-c2-c1" v-for="(item, index) in textList[activeOption]" :key="index">
                        <div style="display: flex;align-items: center;">
                            <div class="pmod-c2-c1-line"></div><span class="pmod-c2-c1-t1">{{ $t(item.title) }}</span>
                        </div>
                        <div style="margin-top: 14px;display: inline-block;line-height: 36px;font-size: 18px;">{{ $t(item.text) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pmod-line1">
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
const activeOption = ref(0)
const options = ref(["mod.1", "mod.2"])
const textList = ref([
    [
        { title: "mod.4", text: "mod.5" },
        { title: "mod.6", text: "mod.7" },
        { title: "mod.8", text: "mod.9" },
        { title: "mod.10", text: "mod.11" },
    ],
    [
        { title: "mod.12", text: "mod.13" },
        { title: "mod.14", text: "mod.15" },
        { title: "mod.16", text: "mod.17" },

    ]
])
function clickOption(index: number) {
    activeOption.value = index
}
</script>
<style scoped>
@import url("./index.css");
</style>