import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from './img/home_icon_android.png'
import _imports_1 from './img/home_icon_ios.png'


const _hoisted_1 = { class: "pindex" }
const _hoisted_2 = { class: "pindex-c1" }
const _hoisted_3 = { class: "pindex-t1" }
const _hoisted_4 = { class: "pindex-t2" }
const _hoisted_5 = { class: "pindex-btn" }
const _hoisted_6 = { class: "pindex-c2" }
const _hoisted_7 = { class: "pindex-c2-t1" }
const _hoisted_8 = { class: "pindex-c2-c1" }
const _hoisted_9 = { class: "pindex-c3" }
const _hoisted_10 = { class: "pindex-c3-t1" }
const _hoisted_11 = { class: "pindex-c3-t2" }
const _hoisted_12 = { class: "pindex-c3-t3" }

import { ref } from "vue";
import card from "./components/card.vue";
import Config from "@/data/config"

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const cardData = ref([
    { src: require("./img/card_img1.png"), title: "index.4", t1: "index.5", t2: "123,125.25", t3: "index.6", p: "250%+" },
    { src: require("./img/card_img2.png"), title: "index.7", t1: "index.8", t2: "123,125.25", t3: "index.6", p: "250%+" }
])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("index.1")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("index.2")), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("index.3")), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("index.15")), 1),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cardData.value, (item, index) => {
          return (_openBlock(), _createBlock(card, {
            title: _ctx.$t(item.title),
            key: index,
            src: item.src,
            t1: _ctx.$t(item.t1),
            t2: _ctx.$t(item.t2),
            t3: _ctx.$t(item.t3)
          }, null, 8, ["title", "src", "t1", "t2", "t3"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("index.9")), 1),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("index.10")), 1),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("index.11")), 1),
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"pindex-c3-c1\" data-v-44176018><div class=\"pindex-c3-btn1\" data-v-44176018><img src=\"" + _imports_0 + "\" width=\"20px\" height=\"20px\" style=\"margin-right:11px;\" data-v-44176018><span data-v-44176018>Android</span></div><div class=\"pindex-c3-btn1\" data-v-44176018><img src=\"" + _imports_1 + "\" width=\"20px\" height=\"20px\" style=\"margin-right:11px;\" data-v-44176018><span data-v-44176018>App store</span></div></div>", 1))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "pindex-line1" }, null, -1))
  ]))
}
}

})