import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/index.vue'
import Mod from "../views/taoliMode.vue"
import Friends from "../views/friends.vue"
import Abount from "../views/about.vue"

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/mod',
    name: 'mod',
    component: Mod
  },
  {
    path: '/friends',
    name: 'friends',
    component: Friends
  },
  {
    path: '/about',
    name: 'about',
    component: Abount
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
