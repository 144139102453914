export default {
    index: {
        1: "智能算法驱动，高频捕捉价差",
        2: "锁定超高回报率",
        3: "申请免费试用",
        4: "现货套利机器人",
        5: "月收益8%, 复利年收益",
        6: "累计套利",
        7: "链上交易机器人",
        8: "月收益8%, 复利年收益",
        9: "下载APP",
        10: "无惧牛熊,智能化套利",
        11: "让资产倍增更简单",
        12: "关于SpotAI",
        13: "合伙人申请",
        14: "支持的交易所",
        15:"套利收益模式",
        16:"在线客服"
    },
    topbar: {
        1: "首页",
        2: "套利模式",
        3: "合伙人",
        4: "关于我们",
    },
    mod: {
        1: "现货套利机器人",//4-11
        2: "链上套利机器人",// 12 -17
        3: "SpotAI的现货套利机器人基于强大的AI算法，能实时分析全球加密货币市场的价格走势，精准捕捉各大交所间的价差机会。通过深度学习技术，AI算法能够自适应市场变化，持续优化套利策略，确保在复杂多变的市场环境中始终保持交易的高效性和智能性。",
        4: "用户资金自主管理",
        5: "用户的资金始终存放在各大交易的个人账号内，机器人通过API授权执行交易操作，所有的交易均在用户的授权范围内执行，用户对资金的存取，管理和使用拥有100%的自主权。",
        6: "零风险套利模式",
        7: "我们的机器人采用严格的本金O风险套利策略，专注不同交易所的价差，我们的机器人能实时监控这些价格差异并在不同交易所同步买入和卖出，产生无风险套利收益。通过这一策略，让用户的本金始终处于0风险的保障下实现稳定盈利。",
        8: "精准捕捉交易所价差",
        9: "加密货币市场瞬息万变，价格波动频繁。我们的现货套利机器人能够快速扫描和分析多个交易所的数据，捕捉任何潜在的价格差异，无论市场处于何种波动状态，机器人都能即时执行套利操作，实现最大化套利收益",
        10: "简单操作，自动执行",
        11: "用户只需在交易所生成API密钥后授权给我们机器人进行交易，系统将24小时监控市场，自动捕捉套利机会并执行交易，整个过程无需人工干预，智能化的系统将为用户带来持续和稳定的收益",
        12: "用户资金自主管理",
        13: "用户的资金始终存放在去中心化交易所(DEX)和中心化交易所(CEX)的个人账户中，机器人的所有操作均授权范围内执行，用户对资金的存取，管理和使用拥有100%的自主权。",
        14: "算法驱动，智能套利",
        15: "机器人使用了先进的AI算法，能够实时监测链上和链下的价格波动，自动发现无风险套利机会。机器人会不断学习和优化交易策略，精准捕捉市场中瞬息万变的套利空间，并在毫秒级的时间内完成交易操作。",
        16: "高并发处理能力",
        17: "链上和链下交易环境充满竞争性和不确定性，我们的机器人具备超高并发处理能力，能够在极短的时间内帮助用户执行数百笔操作。无论在高流动性环境还是在低波动市场，机器人能以极快的速度进行套利，最大限度提高资金利用率。"
    },
    friends: {
        1: "成为合伙人",
        2: "我们正在寻找对数字货币量化交易充满热情的合伙人，尤其希望能够携手拥有丰富资本市场人脉的您，共同开拓市场，实现双赢：",
        3: "合伙人要求",
        4: "*资本市场经验：希望您在资本市场有丰富的从业经验，对数字货币及其量化交易有深入了解；*人脉资源：拥有广泛的行业人脉资源，能否帮助我们拓展市场渠道，推动业务发展；*合作精神：具备良好的沟通能力和团队协作意识，共同推进合作项目的成功；",
        5: "我们的支持",
        6: "*专业的量化交易系统：智能算法，数据驱动，全天天候自动交易；*丰厚佣金和长期收益：加入我们每月赚取百万收益，共享加密货币市场行业红利。",
        7: "咨询详情，请联系Telegram：{'@'}spotai"
    },
    about: {
        1: "关于SpotAI",
        2: "我们的技术团队专注于数字货币现货量化交易，融合先进的人工智能技术，致力于提供高效，精准且安全的自动化交易方案，团队由金融工程师，数据科学家，和AI专家组成，具备深厚的行业背景和技术能力。",
        3: "团队优势",
        4: "*跨学科专业：团队成员来自金融，计算机科学和人工智能等多个领域，能够全面分析市场趋势和潜在机会；*算法驱动：我们开发了多种基于AI的交易算法，利用机器学习和深度学习技术进行市场数据分析，以预测价格变化和优化交易策略；*实时数据处理：团队构建了高效的数据处理平台，能够实时监测和分析海量市场数据，快速识别套利机会，确保交易的准确性和及时性；*态风险控制：结合AI技术，我们实现了智能化的风险管理系统，能够实时识别风险和动效调整策略，以保护投资者的资金安全；*强大的安全保障：我们注重交易和数据的安全，采用多层加密和严格的身份认证机制，确保用户信息和资产安全性，此外团队定期进行安全审计，确保万无一失",
        5: "我们的愿景",
        6: "*我们的目标是将人工智能和量化交易深度结合，为客户提供透明，高效，智能且安全的数字货币投资方案。*通过持续的技术创新和严格的安全措施，我们希望在快速发展的数字货币市场中，树立行业标杆，带领客户实现财富的稳健增长。",

    }
}