<template>
    <div class="friends">
        <PFriends v-if="configStore.isPC"></PFriends>
        <MFriends v-else></MFriends>

    </div>
</template>
<script lang="ts" setup>
import PFriends from "./pc/friends/index.vue"
import MFriends from "./mobile/friends/index.vue"
import useConfigStore from "@/store/config";
const configStore = useConfigStore()
</script>