<template>
    <div class="pindex">
        <div class="pindex-c1">
            <div class="pindex-t1">{{ $t("index.1") }}</div>
            <div class="pindex-t2">{{ $t("index.2") }}</div>
            <div class="pindex-btn">{{ $t("index.3") }}</div>
        </div>
        <div class="pindex-c2">
            <div class="pindex-c2-t1">{{$t("index.15")}}</div>
            <div class="pindex-c2-c1">
                <card v-for="(item, index) in cardData" :title="$t(item.title)" :key="index" :src="item.src" :t1="$t(item.t1)" :t2="$t(item.t2)"
                    :t3="$t(item.t3)"></card>
            </div>
        </div>
        <div class="pindex-c3">
            <div class="pindex-c3-t1">{{$t("index.9")}}</div>
            <div class="pindex-c3-t2">{{$t("index.10")}}</div>
            <div class="pindex-c3-t3">{{$t("index.11")}}</div>
            <div class="pindex-c3-c1">
                <div class="pindex-c3-btn1">
                    <img src="./img/home_icon_android.png" width="20px" height="20px" style="margin-right: 11px;" />
                    <span>Android</span>
                </div>
                <div class="pindex-c3-btn1">
                    <img src="./img/home_icon_ios.png" width="20px" height="20px" style="margin-right: 11px;" />
                    <span>App store</span>
                </div>
            </div>
        </div>
        <div class="pindex-line1">
        </div>

    </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import card from "./components/card.vue";
import Config from "@/data/config"
const cardData = ref([
    { src: require("./img/card_img1.png"), title: "index.4", t1: "index.5", t2: "123,125.25", t3: "index.6", p: "250%+" },
    { src: require("./img/card_img2.png"), title: "index.7", t1: "index.8", t2: "123,125.25", t3: "index.6", p: "250%+" }
])
</script>
<style scoped>
@import url("./index.css");
</style>