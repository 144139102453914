import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../img/bar_icon_back.png'


const _hoisted_1 = { class: "mmod" }
const _hoisted_2 = { class: "mmod-top" }
const _hoisted_3 = { class: "mmod-title" }
const _hoisted_4 = { style: {"padding-top":"10px"} }
const _hoisted_5 = { class: "mmod-c1" }
const _hoisted_6 = { class: "mmod-t1" }
const _hoisted_7 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_8 = { class: "mmod-c2-c1-t1" }
const _hoisted_9 = ["innerHTML"]

import Config from '@/data/config';
import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    activeIndex: {
        type: Number,
        default: 0
    }
},
  setup(__props) {

const props = __props
window.scrollTo({ top: 0 })
const activeOption = ref(props.activeIndex)
const options = ref(["mod.1", "mod.2"])
const textList = ref([
    [
        { title: "mod.4", text: "mod.5" },
        { title: "mod.6", text: "mod.7" },
        { title: "mod.8", text: "mod.9" },
        { title: "mod.10", text: "mod.11" },
    ],
    [
        { title: "mod.12", text: "mod.13" },
        { title: "mod.14", text: "mod.15" },
        { title: "mod.16", text: "mod.17" },

    ]
])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        style: {"width":"30px","height":"30px","left":"40px","position":"absolute","top":"35px"},
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(Config).Router.go(-1)))
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(options.value[activeOption.value])), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("mod.3")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(textList.value[activeOption.value], (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "mmod-c2-c1",
            key: index
          }, [
            _createElementVNode("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mmod-c2-c1-line" }, null, -1)),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(item.title)), 1)
            ]),
            _createElementVNode("div", {
              class: "mmod-t2",
              innerHTML: _unref(Config).turnStar(_ctx.$t(item.text))
            }, null, 8, _hoisted_9)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})