import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import { createPinia } from 'pinia'
// i18n.global.locale.value = 'zh_CN'//默认语言
const pinia = createPinia()
createApp(App).use(router).use(router).use(pinia).use(i18n).use(ElementPlus).mount('#app')
