<template>
    <div class="mabout">
        <div class="mabout-top">
            <img src="../img/bar_icon_back.png"
                style="width: 30px;height: 30px;left: 40px;position: absolute;top: 35px;"
                @click="Config.Router.go(-1)" />
            <div class="mabout-title">{{ $t("about.1") }}</div>

        </div>
        <div style="padding-top: 10px;">
            <div class="mabout-c1">
                <div class="mabout-t1">{{ $t("about.2") }}</div>
                <div class="mabout-c2-c1" v-for="(item, index) in textList" :key="index">
                    <div style="display: flex;align-items: center;">
                        <div class="mabout-c2-c1-line"></div><span class="mabout-c2-c1-t1">{{ $t(item.title) }}</span>
                    </div>
                    <div class="mabout-t2" v-html="Config.turnStar($t(item.text))"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import Config from '@/data/config';
import { ref } from 'vue';
window.scrollTo({top:0})
const textList = ref(
    [
        { title: "about.3", text: "about.4" },
        { title: "about.5", text: "about.6" },
    ]
)
</script>
<style scoped>
@import url("./index.css");
</style>