<template>
    <div>
        <TaoLiMod v-if="configStore.isPC"></TaoLiMod>
        <MTaoLiMod :active-index="mod" v-else></MTaoLiMod>
    </div>
</template>
<script setup lang="ts">
import TaoLiMod from "./pc/taoliMod/index.vue"
import MTaoLiMod from "./mobile/taoliMod/index.vue"
import useConfigStore from "@/store/config";
import { useRoute } from "vue-router";
import { ref } from "vue";
const Route = useRoute()
const mod = ref(0)
let d = Route.query
console.log(d, " url data");
if (d && d["mod"]) {
    const aa: any = d["mod"]
    mod.value = aa
}

const configStore = useConfigStore()
</script>