import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/topBar/logo.png'
import _imports_1 from './img/h5_icon_language.png'
import _imports_2 from './img/h5_icon_fold.png'
import _imports_3 from './img/h5_icon_pull.png'


const _hoisted_1 = { class: "mTopBar" }
const _hoisted_2 = { style: {"display":"inline-block","margin":"0px 8px"} }
const _hoisted_3 = {
  key: 0,
  src: _imports_2,
  class: "mTopBar-img2"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_3,
  class: "mTopBar-img2"
}

import Config from "@/data/config";
import i18n from "@/i18n";
import useConfigStore from "@/store/config";
import { useLangStore } from "@/store/lang";
import { ref } from "vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'mindex',
  setup(__props) {

const langStore = useLangStore()
const configStore = useConfigStore()
const menuList = ref([
    { name: "topbar.1", path: "/", key: "1" },
    { name: "topbar.2", path: "/mod", key: "2" },
    { name: "topbar.3", path: "/friends", key: "3" },
    { name: "topbar.4", path: "/about", key: "4" },
])
const chooseLang = ref(false)
function selectLang(index: number) {
    langStore.nowLang = index
    i18n.global.locale.value = langStore.langList[index].code
    window.localStorage.setItem("lang", langStore.langList[index].code)
    chooseLang.value = false
}
function menuClick(key: string, path: string) {
    configStore.topMenuActive = key
    Config.Router.push(path)

}
function setBarPosition() {
    let div = document.getElementById("menuBar")
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("img", {
      src: _imports_0,
      style: {"width":"169px","height":"30px","margin-left":"40px"},
      class: "mTopBar-img"
    }, null, -1)),
    _createElementVNode("div", {
      class: "mTopBar-langBtn",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (chooseLang.value = !chooseLang.value))
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        src: _imports_1,
        style: {"width":"22px","height":"22px","margin-left":"24px"}
      }, null, -1)),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(langStore).langList[_unref(langStore).nowLang].name), 1),
      (chooseLang.value)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_openBlock(), _createElementBlock("img", _hoisted_4)),
      _withDirectives(_createElementVNode("div", {
        class: "mTopBar-langDrop",
        onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("span", {
          class: "mTopBar-langDrop-item",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (selectLang(0)))
        }, "繁体中文"),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mTopBar-line" }, null, -1)),
        _createElementVNode("span", {
          class: "mTopBar-langDrop-item",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (selectLang(1)))
        }, "简体中文"),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mTopBar-line" }, null, -1)),
        _createElementVNode("span", {
          class: "mTopBar-langDrop-item",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (selectLang(2)))
        }, "English")
      ], 512), [
        [_vShow, chooseLang.value]
      ])
    ])
  ]))
}
}

})