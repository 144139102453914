import { defineStore } from "pinia";
import { ref } from "vue";

export const useLangStore = defineStore("lang", () => {

    const nowLang = ref(1)
    const langList = ref([
        { code: "zh_F", name: "繁体中文" },
        { code: "zh_CN", name: "简体中文" },
        { code: "en", name: "English" }
    ])
    
    return {
        nowLang,
        langList
    }
})