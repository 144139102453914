import { createI18n } from 'vue-i18n'

import zh_CN from './lang/zh/common' //中文
import EN from './lang/en/common' //英文
import zh_F from './lang/zh_F/common' //英文


//语言配置信息
const message: any = {
  zh_CN: {
    //中文简体
    ...zh_CN,
  },
  en: {
    //英文
    ...EN,
  },
  zh_F: {
    //繁体
    ...zh_F,
  },
}

//系统语言（全局默认语言），取浏览器语言
export const SYS_LANG: any = getSysLang()

//获取当前语言
function getSysLang() {
  let sysLang: any = (window as any).navigator.language || 'zh_CN'
  sysLang = sysLang.replace('-', '_')
  return sysLang
}

//创建i18n实例
const i18n = createI18n({
  locale: (window as any).localStorage.getItem('lang') || SYS_LANG, // 设置当前语言类型，若本地没存，则设置默认语言
  fallbackLocale: 'zh_CN', //如果出错，则默认的语言：中文简体

  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages: message,

  silentTranslationWarn: true, // 去掉警告
  missingWarn: false,
  silentFallbackWarn: true, //抑制警告
})

export default i18n