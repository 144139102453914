export default {
    index: {
        1: "Intelligent algorithm driven, high-frequency capture price difference",
        2: "Lock in super returns",
        3: "Request a free trial",
        4: "Spot arbitrage robot",
        5: "Monthly return of 8%, compound annual return",
        6: "Accumulated arbitrage",
        7: "On chain trading robot",
        8: "Monthly return of 8%, compound annual return",
        9: "Download the app",
        10: "Fearless of bulls and bears, intelligent arbitrage",
        11: "Making asset doubling easier",
        12: "About SpotAI",
        13: "Partner application",
        14: "Supported Exchanges",
        15: "Arbitrage return model",
        16:"Online Service "
    },
    topbar: {
        1: "Home",
        2: "Arbitrage Model",
        3: "Partner",
        4: "AboutUS",
    },
    mod: {
        1: "Spot arbitrage robot",//4-11
        2: "On-chain arbitrage bot",// 12 -17
        3: "SpotAI's spot arbitrage robot, leveraging powerful AI algorithms, can analyze the price trends of the global cryptocurrency market in real-time, accurately seizing price discrepancies across major exchanges. Through deep learning technology, the AI algorithm can adapt to market changes, continuously optimize arbitrage strategies, and ensure efficient and intelligent trading in complex and volatile market environments.",
        4: "Independent management of user funds",
        5: "Users' funds are always stored in their personal accounts on major exchanges. The robot executes trading operations through API authorization, and all transactions are executed within the scope of user authorization. Users have 100% autonomy over the deposit, withdrawal, management, and use of their funds.",
        6: "Zero-risk arbitrage model",
        7: "Our robot adopts a strict principal-only, zero-risk arbitrage strategy, focusing on price differences across various exchanges. Our robot can monitor these price discrepancies in real-time and synchronously buy and sell on different exchanges, generating risk-free arbitrage profits. Through this strategy, users' principal is always protected with zero risk, enabling stable profitability.",
        8: "Accurately capture the price spreads on exchanges",
        9: "The cryptocurrency market is rapidly changing, with frequent price fluctuations. Our spot arbitrage robot can quickly scan and analyze data from multiple exchanges, capturing any potential price discrepancies. Regardless of the market's volatility, the robot can instantly execute arbitrage operations to maximize arbitrage profits",
        10: "Simple operation, automatic execution",
        11: "Users only need to generate an API key on the exchange and authorize our robot to conduct transactions. The system will monitor the market 24 hours a day, automatically capture arbitrage opportunities and execute transactions. The entire process requires no human intervention, and the intelligent system will bring continuous and stable benefits to users",
        12: "Autonomous management of user funds",
        13: "Users' funds are always stored in their personal accounts on decentralized exchanges (DEX) and centralized exchanges (CEX). All operations conducted by bots are executed within authorized limits. Users retain 100% autonomy over the deposit, withdrawal, management, and utilization of their funds.",
        14: "Algorithm-driven, intelligent arbitrage",
        15: "The robot employs advanced AI algorithms, enabling it to monitor price fluctuations both on and off the chain in real time, automatically identifying risk-free arbitrage opportunities. The robot continuously learns and optimizes its trading strategies, precisely capturing the rapidly changing arbitrage opportunities in the market and completing transaction operations within milliseconds.",
        16: "High concurrency processing capability",
        17: "The on-chain and off-chain trading environments are fraught with competitiveness and uncertainty. Our robots possess ultra-high concurrency processing capabilities, enabling them to assist users in executing hundreds of operations in an extremely short period of time. Whether in high liquidity environments or low volatility markets, the robots can arbitrage at an extremely fast speed, maximizing capital utilization."
    },
    friends: {
        1: "Become a partner",
        2: "We are seeking partners who are passionate about quantitative trading of digital currencies. We particularly hope to collaborate with you, who possesses extensive connections in the capital market, to jointly explore the market and achieve a win-win situation:",
        3: "Partner requirements",
        4: "*Capital market experience: We hope you have extensive experience in the capital market and a deep understanding of digital currencies and their quantitative trading;*Network resources: Possess extensive industry network resources and be able to help us expand market channels and promote business development;*Cooperative spirit: Possess good communication skills and a sense of teamwork, and jointly promote the success of cooperative projects;",
        5: "Our support",
        6: "*Professional quantitative trading system: intelligent algorithms, data-driven, automated trading around the clock; *Generous commissions and long-term returns: join us and earn millions of income monthly, sharing the dividends of the cryptocurrency market industry.",
        7: "For more information, please contact Telegram: {'@'}spotai"
    },
    about: {
        1: "About SpotAI",
        2: "Our technical team specializes in quantitative trading of digital currency spot markets, integrating cutting-edge artificial intelligence technology. We are committed to delivering efficient, precise, and secure automated trading solutions. Comprised of financial engineers, data scientists, and AI experts, our team possesses profound industry backgrounds and technical capabilities.",
        3: "Team Advantages",
        4: "*Interdisciplinary expertise: Our team comprises members from various fields such as finance, computer science, and artificial intelligence, enabling us to comprehensively analyze market trends and potential opportunities;*Algorithm-driven: We have developed multiple AI-based trading algorithms that utilize machine learning and deep learning techniques for market data analysis, aiming to predict price changes and optimize trading strategies;*Real-time data processing: Our team has built an efficient data processing platform that can monitor and analyze massive market data in real time, quickly identifying arbitrage opportunities and ensuring the accuracy and timeliness of transactions;*Dynamic risk control: By integrating AI technology, we have implemented an intelligent risk management system that can identify risks in real time and dynamically adjust strategies to protect investors' capital safety;*Strong security guarantee: We prioritize transaction and data security, employing multi-layer encryption and strict identity authentication mechanisms to ensure the security of user information and assets. Additionally, our team conducts regular security audits to ensure zero errors or vulnerabilities",
        5: "Our Vision",
        6: "*Our goal is to deeply integrate artificial intelligence with quantitative trading, providing customers with transparent, efficient, intelligent, and secure digital currency investment solutions. *Through continuous technological innovation and stringent security measures, we aspire to establish an industry benchmark in the rapidly evolving digital currency market, guiding our customers towards steady wealth growth.",

    }
}