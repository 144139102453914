<template>
    <div class="index">
        <PIndex v-if="configStore.isPC"></PIndex>
        <MIndex v-else></MIndex>
    </div>
</template>
<script lang="ts" setup>
import PIndex from "./pc/index/index.vue"
import MIndex from "./mobile/index/index.vue"
import Config from "@/data/config"
import useConfigStore from "@/store/config";
const configStore = useConfigStore()
</script>
<style scoped></style>