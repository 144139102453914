import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../img/bar_icon_back.png'


const _hoisted_1 = { class: "mfriend" }
const _hoisted_2 = { class: "mfriend-top" }
const _hoisted_3 = { class: "mfriend-title" }
const _hoisted_4 = { style: {"padding-top":"10px"} }
const _hoisted_5 = { class: "mfriend-c1" }
const _hoisted_6 = { class: "mfriend-t1" }
const _hoisted_7 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_8 = { class: "mfriend-c2-c1-t1" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  class: "mfriend-t2",
  style: {"text-align":"left","padding-left":"30px"}
}

import Config from '@/data/config';
import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

window.scrollTo({top:0})
const textList = ref(
    [
        { title: "friends.3", text: "friends.4" },
        { title: "friends.5", text: "friends.6" },
    ]
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        style: {"width":"30px","height":"30px","left":"40px","position":"absolute","top":"35px"},
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(Config).Router.go(-1)))
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("friends.1")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("friends.2")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(textList.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "mfriend-c2-c1",
            key: index
          }, [
            _createElementVNode("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mfriend-c2-c1-line" }, null, -1)),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(item.title)), 1)
            ]),
            _createElementVNode("div", {
              class: "mfriend-t2",
              innerHTML: _unref(Config).turnStar(_ctx.$t(item.text))
            }, null, 8, _hoisted_9)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("friends.7")), 1)
  ]))
}
}

})