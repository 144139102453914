export default {
    index: {
        1: "智慧算灋驅動，高頻捕捉價差",
        2: "鎖定超高回報率",
        3: "申請免費試用",
        4: "現貨套利機器人",
        5: "月收益8%，複利年收益",
        6: "累計套利",
        7: "鏈上交易機器人",
        8: "月收益8%，複利年收益",
        9: "下載APP",
        10: "無懼牛熊，智能化套利",
        11: "讓資產倍增更簡單",
        12: "關於SpotAI",
        13: "合夥人申請",
        14: "支持的交易所",
        15: "套利收益模式",
        16:"線上客服"
    },
    topbar: {
        1: "首頁",
        2: "套利模式",
        3: "合夥人",
        4: "關於我們",
    },
    mod: {
        1: "現貨套利機器人",//4-11
        2: "鏈上套利機器人",// 12 -17
        3: "SpotAI的現貨套利機器人基於强大的AI算灋，能實时分析全球加密貨幣市場的價格走勢，精准捕捉各大交所間的價差機會。 通過深度學習科技，AI算灋能够自我調整市場變化，持續優化套利策略，確保在複雜多變的市場環境中始終保持交易的高效性和智慧性。",
        4: "用戶資金自主管理",
        5: "用戶的資金始終存放在各大交易的個人帳號內，機器人通過API授權執行交易操作，所有的交易均在用戶的授權範圍內執行，用戶對資金的存取，管理和使用擁有100%的自主權。",
        6: "零風險套利模式",
        7: "我們的機器人採用嚴格的本金O風險套利策略，專注不同交易所的價差，我們的機器人能即時監控這些價格差异並在不同交易所同步買入和賣出，產生無風險套利收益。 通過這一策略，讓用戶的本金始終處於0風險的保障下實現穩定盈利。",
        8: "精准捕捉交易所價差",
        9: "加密貨幣市場瞬息萬變，價格波動頻繁。 我們的現貨套利機器人能够快速掃描和分析多個交易所的數據，捕捉任何潜在的價格差异，無論市場處於何種波動狀態，機器人都能即時執行套利操作，實現最大化套利收益",
        10: "簡單操作，自動執行",
        11: "用戶只需在交易所生成API金鑰後授權給我們機器人進行交易，系統將24小時監控市場，自動捕捉套利機會並執行交易，整個過程無需人工干預，智能化的系統將為用戶帶來持續和穩定的收益",
        12: "用戶資金自主管理",
        13: "用戶的資金始終存放在去中心化交易所（DEX）和中心化交易所（CEX）的個人帳戶中，機器人的所有操作均授權範圍內執行，用戶對資金的存取，管理和使用擁有100%的自主權。",
        14: "算灋驅動，智慧套利",
        15: "機器人使用了先進的AI算灋，能够即時監測鏈上和鏈下的價格波動，自動發現無風險套利機會。 機器人會不斷學習和優化交易策略，精准捕捉市場中瞬息萬變的套利空間，並在毫秒級的時間內完成交易操作。",
        16: "高併發處理能力",
        17: "鏈上和鏈下交易環境充滿競爭性和不確定性，我們的機器人具備超高併發處理能力，能够在極短的時間內幫助用戶執行數百筆操作。 無論在高流動性環境還是在低波動市場，機器人能以極快的速度進行套利，最大限度提高資金利用率。"
    },
    friends: {
        1: "成為合夥人",
        2: "我們正在尋找對數位貨幣量化交易充滿熱情的合夥人，尤其希望能夠攜手擁有豐富資本市場人脈的您，共同開拓市場，實現雙贏；",
        3: "合夥人要求",
        4: "*資本市場經驗：希望您在資本市場有豐富的從業經驗，對數位貨幣及其量化交易有深入瞭解；*人脈資源：擁有廣泛的行業人脈資源，能否幫助我們拓展市場通路，推動業務發展；*合作精神：具備良好的溝通能力和團隊合作意識，共同推進合作項目的成功；",
        5: "我們的支持",
        6: "*專業的量化交易系統：智慧算灋，數據驅動，全天天候自動交易；*豐厚傭金和長期收益：加入我們每月賺取百萬收益，共亯加密貨幣市場行業紅利。",
        7: "諮詢詳情，請聯系Telegram:{'@'}spotai"
    },
    about: {
        1: "關於SpotAI",
        2: "我們的科技團隊專注於數位貨幣現貨量化交易，融合先進的人工智慧科技，致力於提供高效，精准且安全的自動化交易方案，團隊由金融工程師，數據科學家，和AI專家組成，具備深厚的行業背景和科技能力。",
        3: "團隊優勢",
        4: "*跨學科專業：團隊成員來自金融，計算機科學和人工智慧等多個領域，能够全面分析市場趨勢和潜在機會；*算灋驅動：我們開發了多種基於AI的交易算灋，利用機器學習和深度學習科技進行市場資料分析，以預測價格變化和優化交易策略；*實时資料處理：團隊構建了高效的資料處理平臺，能够即時監測和分析海量市場數據，快速識別套利機會，確保交易的準確性和及時性；*態風險控制：結合AI科技，我們實現了智能化的風險管理系統，能够實时識別風險和動效調整策略，以保護投資者的資金安全；*强大的安全保障：我們注重交易和數據的安全，採用多層加密和嚴格的身份認證機制，確保用戶資訊和資產安全性，此外團隊定期進行安全稽核，確保萬無一失",
        5: "我們的願景",
        6: "*我們的目標是將人工智慧和量化交易深度結合，為客戶提供透明，高效，智慧且安全的數位貨幣投資方案。*通過持續的技術創新和嚴格的安全措施，我們希望在快速發展的數位貨幣市場中，樹立行業標杆，帶領客戶實現財富的穩健增長。",

    }
}