import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { useRouter } from "vue-router"
import TopBar from "@/components/TopBar/index.vue"

import BottomBar from "@/components/BottomBar/index.vue"
import kefu from "@/components/kefu/index.vue"
import { onMounted } from "vue"
import Config from "@/data/config"
import useConfigStore from "./store/config"
import { useLangStore } from "./store/lang"

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const configStore = useConfigStore()
Config.getScale()
onMounted(() => {
  const lang = window.localStorage.getItem("lang")
  if (lang) {
    useLangStore().nowLang = lang == "zh" ? 0 : lang == "en" ? 2 : 1
  }
  Config.Router = useRouter()
  windowReize()
  Config.Router.beforeEach((to, from, next) => {
    if (to.path == "/friends") configStore.topMenuActive = "3";
    if (to.path == "/mod") configStore.topMenuActive = "2";
    if (to.path == "/about") configStore.topMenuActive = "4";
    next()
  })
})
window.onresize = () => {
  windowReize()
}
function windowReize() {
  const a = Config.judge_m_or_p()
  configStore.isPC = a == "m" ? false : true
  console.log(configStore.isPC, "  ispc");
  let scale = Config.getScale()
  document.body.style.width = configStore.isPC ? "1920px" : "750px";

  // // document.body.style.height = height + "px";
  // // 设置body缩放

  // document.body.style.transform = `scale(${scale})`;
  // document.body.style.transformOrigin = '0 0';
  document.body.style.zoom = scale + ""
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(configStore).isPC)
      ? (_openBlock(), _createBlock(TopBar, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(kefu),
    (_unref(configStore).isPC)
      ? (_openBlock(), _createBlock(BottomBar, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})