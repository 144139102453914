import { Router } from "vue-router"
import useConfigStore from "@/store/config"
export default class Config {
    public static Router: Router
    public static turnStar(txt: String) {
        const arr = txt.split("*")
        let str= ""
        for(let i=0; i < arr.length; i++){
            console.log(arr[i])
            const a = arr[i].replaceAll("*","")
            if(a=="")continue;
            str+="<div class='c-c'><span class='textStar'>*</span><div class='c-c-t'>"+a+"</div></div>"
        }
        return str
    }
    /**
 * 判断当前设备移动端还是pc端
 * m 为移动端
 * p 为pc端
 * 失败则返回错误
 */
    public static judge_m_or_p(): any {
        try {
            if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
                // console.log("移动端")
                return 'm'
            } else {
                //    console.log("pc端");
                return 'p'
            }
        } catch (e) {
            console.log(e);

            return "p"
        }
    }
    public static getScale() {
        const configStore = useConfigStore()
        const w = configStore.isPC ? 1920 : 750
        const _w = configStore.isPC ? window.innerWidth - 16 : window.screen.width
        const scale = _w / w
        // Config.scale = scale >= 1 ? 1 : scale <= 0.5 ? 0.5 : scale


        configStore.scale = scale
        console.log("scale", scale, _w);

        return scale
    }
}