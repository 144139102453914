import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from './home_icon_cus.png'
import _imports_1 from './h5_img_cus.png'


const _hoisted_1 = { style: {"position":"relative","width":"100%","display":"flex","justify-content":"center"} }
const _hoisted_2 = { class: "kefu-btn" }
const _hoisted_3 = { style: {"display":"inline-block","flex":"none","padding":"0px 13px","margin":"0px 13px"} }
const _hoisted_4 = { style: {"position":"relative","width":"100%","display":"flex","justify-content":"center"} }
const _hoisted_5 = { class: "kefu-phone-btn" }

import useConfigStore from '@/store/config';
import { useLangStore } from '@/store/lang';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const langStore = useLangStore()
const configStore = useConfigStore()
function clickEvent() {
    window.location.href = "https://www.baidu.com"
}

return (_ctx: any,_cache: any) => {
  return (_unref(configStore).isPC)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "kefu",
        onClick: clickEvent
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          width: "76px",
          height: "83px",
          style: {"position":"relative","top":"-6"}
        }, null, -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("index.16")), 1)
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "kefu-phone",
        onClick: clickEvent
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          src: _imports_1,
          width: "106px",
          height: "125px",
          style: {"position":"relative","top":"-6"}
        }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              style: {"display":"inline-block","flex":"none","line-height":"22px"},
              class: _normalizeClass({ 'kefu-en': _unref(langStore).nowLang == 2 })
            }, _toDisplayString(_ctx.$t("index.16")), 3)
          ])
        ])
      ]))
}
}

})