import { defineStore } from "pinia"
import { ref } from "vue"

const useConfigStore = defineStore("config", () => {
    const scale = ref(1)
    const isPC=ref(false)
    const topMenuActive = ref("1")
    return {
        scale,
        topMenuActive,
        isPC
    }
})
export default useConfigStore