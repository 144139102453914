<template>
    <div class="mindex">
        <MTopBar></MTopBar>
        <div class="mindex-c1">
            <div class="mindex-t1">
                <div class="mindex-t1-1">
                    {{ $t("index.1") }}
                </div>
            </div>
            <div class="mindex-t2">{{ $t("index.2") }}</div>
            <div class="mindex-btn1">{{ $t("index.3") }}</div>
        </div>
        <div class="mindex-c2" style="margin-top: 74px;">
            <card v-for="(item, index) in cardData" :title="$t(item.title)" :key="index" :src="item.src"
                :t1="$t(item.t1)" :t2="$t(item.t2)" :t3="$t(item.t3)" :index="index"></card>
        </div>
        <div class="mindex-c3">
            <div class="mindex-c3-t1">{{ $t("index.9") }}</div><br />
            <div class="mindex-c3-t2">
                {{ $t("index.10") }}
                <br />
                <div class="mindex-c3-t3">{{ $t("index.11") }}</div>
            </div>

            <img src="./img/h5_img_download.png" class="mindex-c3-img1" />
            <div class="mindex-c3-c1">
                <div class="mindex-c3-btn1">
                    <img src="./img/h5_img_android.png" width="26px" height="28px" style="margin-right: 14px;" />
                    <span>Android</span>
                </div>
                <div class="mindex-c3-btn1">
                    <img src="./img/h5_img_ios.png" width="23px" height="28px" style="margin-right: 14px;" />
                    <span>App store</span>
                </div>
            </div>
        </div>
        <div class="mindex-bottom" style="margin-top: 10px;">
            <div v-for="(item, index) in bottomMenuList" :key="index" class="mindex-bottom-item"
                @click="Config.Router.push(item.path)">
                <span style="display: inline-block;margin-left: 50px;color: #DEDEDE;font-size: 26px;">{{ $t(item.name)
                    }}</span>
                <img src="./img/h5_icon_go.png" style="width: 14px; height: 24px;margin-right: 48px;"
                    v-show="item.path != ''" />
            </div>
            <div style="margin-top: 40px;margin-bottom: 91px;">
                <img v-for="(item, index) in bottomIcons" :key="index" :src="item"
                    style="width: 50px;height: 50px;margin: 0px 18px;" />
            </div>
            <div
                style="font-size: 24px;color: rgba(255, 255, 255, 0.3);text-align: center;margin-bottom: 20px;font-family: 'default';">
                Copyright © 2024, google.com
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import card from "./component/card.vue";
import MTopBar from "@/components/TopBar/mindex.vue"

import Config from "@/data/config"
const bottomMenuList = ref([
    { name: "index.12", path: "/about" },
    { name: "index.13", path: "/friends" },
    { name: "index.14", path: "" },
])
const bottomIcons = ref([
    require("./img/coin/h5_img_exchange1.png"),
    require("./img/coin/h5_img_exchange2.png"),
    require("./img/coin/h5_img_exchange3.png"),
    require("./img/coin/h5_img_exchange4.png"),
    require("./img/coin/h5_img_exchange5.png"),
    require("./img/coin/h5_img_exchange6.png"),
    require("./img/coin/h5_img_exchange7.png"),
    require("./img/coin/h5_img_exchange8.png"),

])
const cardData = ref([
    { src: require("./img/h5_icon_spot.png"), title: "index.4", t1: "index.5", t2: "123,125.25", t3: "index.6", p: "250%+", },
    { src: require("./img/h5_icon_online.png"), title: "index.7", t1: "index.8", t2: "123,125.25", t3: "index.6", p: "250%+" }
])
</script>
<style scoped>
@import url("./index.css");
</style>