import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import TaoLiMod from "./pc/taoliMod/index.vue"
import MTaoLiMod from "./mobile/taoliMod/index.vue"
import useConfigStore from "@/store/config";
import { useRoute } from "vue-router";
import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'taoliMode',
  setup(__props) {

const Route = useRoute()
const mod = ref(0)
let d = Route.query
console.log(d, " url data");
if (d && d["mod"]) {
    const aa: any = d["mod"]
    mod.value = aa
}

const configStore = useConfigStore()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(configStore).isPC)
      ? (_openBlock(), _createBlock(TaoLiMod, { key: 0 }))
      : (_openBlock(), _createBlock(MTaoLiMod, {
          key: 1,
          "active-index": mod.value
        }, null, 8, ["active-index"]))
  ]))
}
}

})