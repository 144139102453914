<template>
    <div class="mmod">
        <div class="mmod-top">
            <img src="../img/bar_icon_back.png"
                style="width: 30px;height: 30px;left: 40px;position: absolute;top: 35px;"
                @click="Config.Router.go(-1)" />
            <div class="mmod-title">{{ $t(options[activeOption]) }}</div>

        </div>
        <div style="padding-top: 10px;">
            <div class="mmod-c1">
                <div class="mmod-t1">{{ $t("mod.3") }}</div>
                <div class="mmod-c2-c1" v-for="(item, index) in textList[activeOption]" :key="index">
                    <div style="display: flex;align-items: center;">
                        <div class="mmod-c2-c1-line"></div><span class="mmod-c2-c1-t1">{{ $t(item.title) }}</span>
                    </div>
                    <div class="mmod-t2" v-html="Config.turnStar($t(item.text))"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import Config from '@/data/config';
import { ref, defineProps } from 'vue';
const props = defineProps({
    activeIndex: {
        type: Number,
        default: 0
    }
})
window.scrollTo({ top: 0 })
const activeOption = ref(props.activeIndex)
const options = ref(["mod.1", "mod.2"])
const textList = ref([
    [
        { title: "mod.4", text: "mod.5" },
        { title: "mod.6", text: "mod.7" },
        { title: "mod.8", text: "mod.9" },
        { title: "mod.10", text: "mod.11" },
    ],
    [
        { title: "mod.12", text: "mod.13" },
        { title: "mod.14", text: "mod.15" },
        { title: "mod.16", text: "mod.17" },

    ]
])
</script>
<style scoped>
@import url("./index.css");
</style>