<template>
    <div class="about">
        <PAbout v-if="configStore.isPC"></PAbout>
        <MAbout v-else></MAbout>
    </div>
</template>
<script lang="ts" setup>
import PAbout from "./pc/about/index.vue"
import MAbout from "./mobile/about/index.vue"
import useConfigStore from "@/store/config";
const configStore = useConfigStore()
</script>