import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pcIndex-card" }
const _hoisted_2 = { class: "pcIndex-card-icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pcIndex-card-title" }
const _hoisted_5 = { class: "pcIndex-card-t1" }
const _hoisted_6 = { class: "pcIndex-card-t2" }
const _hoisted_7 = { class: "pcIndex-card-t3" }



export default /*@__PURE__*/_defineComponent({
  __name: 'card',
  props: {
    src: { type: String, default: () => { return require("../img/card_img1.png") } },
    title: { type: String, default: "现货套利机器人" },
    t1: { type: String, default: "月收益8%, 复利年收益250%+" },
    t2: { type: String, default: "123,125.25 USDT" },
    t3: { type: String, default: "累计套利" },
    p: { type: String, default: "250%+" },

},
  setup(__props) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: props.src,
        width: "114px",
        height: "114px"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(props.title), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(props.t1+props.p), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(props.t2+" USDT"), 1),
    _createElementVNode("div", _hoisted_7, _toDisplayString(props.t3), 1)
  ]))
}
}

})