<template>
    <div class="mTopBar">
        <img src="@/assets/topBar/logo.png" style="width: 169px;height: 30px;margin-left: 40px;" class="mTopBar-img" />
        <div class="mTopBar-langBtn" @click="chooseLang = !chooseLang">
            <img src="./img/h5_icon_language.png" style="width: 22px;height: 22px;margin-left: 24px;" />
            <span style="display: inline-block;margin: 0px 8px;">{{ langStore.langList[langStore.nowLang].name
                }}</span>
            <img src="./img/h5_icon_fold.png" v-if="chooseLang" class="mTopBar-img2" />
            <img src="./img/h5_icon_pull.png" v-else class="mTopBar-img2"/>
            <div class="mTopBar-langDrop" v-show="chooseLang" @click.stop>
                <span class="mTopBar-langDrop-item" @click="selectLang(0)">繁体中文</span>
                <div class="mTopBar-line"></div>
                <span class="mTopBar-langDrop-item" @click="selectLang(1)">简体中文</span>
                <div class="mTopBar-line"></div>
                <span class="mTopBar-langDrop-item" @click="selectLang(2)">English</span>
            </div>
        </div>
    </div>
</template>
<style scoped>
@import url("./mindex.css");
</style>
<script lang="ts" setup>
import Config from "@/data/config";
import i18n from "@/i18n";
import useConfigStore from "@/store/config";
import { useLangStore } from "@/store/lang";
import { ref } from "vue"
const langStore = useLangStore()
const configStore = useConfigStore()
const menuList = ref([
    { name: "topbar.1", path: "/", key: "1" },
    { name: "topbar.2", path: "/mod", key: "2" },
    { name: "topbar.3", path: "/friends", key: "3" },
    { name: "topbar.4", path: "/about", key: "4" },
])
const chooseLang = ref(false)
function selectLang(index: number) {
    langStore.nowLang = index
    i18n.global.locale.value = langStore.langList[index].code
    window.localStorage.setItem("lang", langStore.langList[index].code)
    chooseLang.value = false
}
function menuClick(key: string, path: string) {
    configStore.topMenuActive = key
    Config.Router.push(path)

}
function setBarPosition() {
    let div = document.getElementById("menuBar")
}
</script>