import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "about" }
const _hoisted_2 = { style: {"height":"916px","padding-top":"50px"} }
const _hoisted_3 = { class: "about-c1" }
const _hoisted_4 = { class: "about-title" }
const _hoisted_5 = { class: "about-t1" }
const _hoisted_6 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_7 = { class: "about-c2-c1-t1" }
const _hoisted_8 = ["innerHTML"]

import Config from '@/data/config';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const textList = ref(
    [
        { title: "about.3", text: "about.4" },
        { title: "about.5", text: "about.6" },
    ]
)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("about.1")), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("about.2")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(textList.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "about-c2-c1",
            key: index
          }, [
            _createElementVNode("div", _hoisted_6, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "about-c2-c1-line" }, null, -1)),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(item.title)), 1)
            ]),
            _createElementVNode("div", {
              style: {"margin-top":"14px","display":"inline-block","line-height":"36px","font-size":"18px"},
              innerHTML: _unref(Config).turnStar(_ctx.$t(item.text))
            }, null, 8, _hoisted_8)
          ]))
        }), 128))
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "about-line1" }, null, -1))
  ]))
}
}

})