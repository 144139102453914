import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from './img/h5_img_download.png'
import _imports_1 from './img/h5_img_android.png'
import _imports_2 from './img/h5_img_ios.png'
import _imports_3 from './img/h5_icon_go.png'


const _hoisted_1 = { class: "mindex" }
const _hoisted_2 = { class: "mindex-c1" }
const _hoisted_3 = { class: "mindex-t1" }
const _hoisted_4 = { class: "mindex-t1-1" }
const _hoisted_5 = { class: "mindex-t2" }
const _hoisted_6 = { class: "mindex-btn1" }
const _hoisted_7 = {
  class: "mindex-c2",
  style: {"margin-top":"74px"}
}
const _hoisted_8 = { class: "mindex-c3" }
const _hoisted_9 = { class: "mindex-c3-t1" }
const _hoisted_10 = { class: "mindex-c3-t2" }
const _hoisted_11 = { class: "mindex-c3-t3" }
const _hoisted_12 = {
  class: "mindex-bottom",
  style: {"margin-top":"10px"}
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { style: {"display":"inline-block","margin-left":"50px","color":"#DEDEDE","font-size":"26px"} }
const _hoisted_15 = {
  src: _imports_3,
  style: {"width":"14px","height":"24px","margin-right":"48px"}
}
const _hoisted_16 = { style: {"margin-top":"40px","margin-bottom":"91px"} }
const _hoisted_17 = ["src"]

import { ref } from "vue";
import card from "./component/card.vue";
import MTopBar from "@/components/TopBar/mindex.vue"

import Config from "@/data/config"

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const bottomMenuList = ref([
    { name: "index.12", path: "/about" },
    { name: "index.13", path: "/friends" },
    { name: "index.14", path: "" },
])
const bottomIcons = ref([
    require("./img/coin/h5_img_exchange1.png"),
    require("./img/coin/h5_img_exchange2.png"),
    require("./img/coin/h5_img_exchange3.png"),
    require("./img/coin/h5_img_exchange4.png"),
    require("./img/coin/h5_img_exchange5.png"),
    require("./img/coin/h5_img_exchange6.png"),
    require("./img/coin/h5_img_exchange7.png"),
    require("./img/coin/h5_img_exchange8.png"),

])
const cardData = ref([
    { src: require("./img/h5_icon_spot.png"), title: "index.4", t1: "index.5", t2: "123,125.25", t3: "index.6", p: "250%+", },
    { src: require("./img/h5_icon_online.png"), title: "index.7", t1: "index.8", t2: "123,125.25", t3: "index.6", p: "250%+" }
])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MTopBar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("index.1")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("index.2")), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("index.3")), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cardData.value, (item, index) => {
        return (_openBlock(), _createBlock(card, {
          title: _ctx.$t(item.title),
          key: index,
          src: item.src,
          t1: _ctx.$t(item.t1),
          t2: _ctx.$t(item.t2),
          t3: _ctx.$t(item.t3),
          index: index
        }, null, 8, ["title", "src", "t1", "t2", "t3", "index"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("index.9")), 1),
      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_10, [
        _createTextVNode(_toDisplayString(_ctx.$t("index.10")) + " ", 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("index.11")), 1)
      ]),
      _cache[2] || (_cache[2] = _createStaticVNode("<img src=\"" + _imports_0 + "\" class=\"mindex-c3-img1\" data-v-5638d862><div class=\"mindex-c3-c1\" data-v-5638d862><div class=\"mindex-c3-btn1\" data-v-5638d862><img src=\"" + _imports_1 + "\" width=\"26px\" height=\"28px\" style=\"margin-right:14px;\" data-v-5638d862><span data-v-5638d862>Android</span></div><div class=\"mindex-c3-btn1\" data-v-5638d862><img src=\"" + _imports_2 + "\" width=\"23px\" height=\"28px\" style=\"margin-right:14px;\" data-v-5638d862><span data-v-5638d862>App store</span></div></div>", 2))
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bottomMenuList.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mindex-bottom-item",
          onClick: ($event: any) => (_unref(Config).Router.push(item.path))
        }, [
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t(item.name)), 1),
          _withDirectives(_createElementVNode("img", _hoisted_15, null, 512), [
            [_vShow, item.path != '']
          ])
        ], 8, _hoisted_13))
      }), 128)),
      _createElementVNode("div", _hoisted_16, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bottomIcons.value, (item, index) => {
          return (_openBlock(), _createElementBlock("img", {
            key: index,
            src: item,
            style: {"width":"50px","height":"50px","margin":"0px 18px"}
          }, null, 8, _hoisted_17))
        }), 128))
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"font-size":"24px","color":"rgba(255, 255, 255, 0.3)","text-align":"center","margin-bottom":"20px","font-family":"'default'"} }, " Copyright © 2024, google.com ", -1))
    ])
  ]))
}
}

})