<template>
    <div class="kefu" v-if="configStore.isPC" @click="clickEvent">
        <img src="./home_icon_cus.png" width="76px" height="83px" style="position: relative;top: -6;" />
        <div style="position: relative;width: 100%;display: flex;justify-content: center;">
            <div class="kefu-btn">
                <div style="display: inline-block;flex: none;padding: 0px 13px;margin: 0px 13px;">{{ $t("index.16") }}
                </div>
            </div>
        </div>

    </div>
    <div v-else class="kefu-phone" @click="clickEvent">
        <img src="./h5_img_cus.png" width="106px" height="125px" style="position: relative;top: -6;" />
        <div style="position: relative;width: 100%;display: flex;justify-content: center;">
            <div class="kefu-phone-btn">
                <span style="display: inline-block;flex: none;line-height: 22px;" :class="{ 'kefu-en': langStore.nowLang == 2 }">{{
                    $t("index.16") }}
                </span>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import useConfigStore from '@/store/config';
import { useLangStore } from '@/store/lang';
const langStore = useLangStore()
const configStore = useConfigStore()
function clickEvent() {
    window.location.href = "https://www.baidu.com"
}
</script>
<style scoped>
.kefu {
    width: 90px;
    height: 97px;
    position: fixed;
    right: 60px;
    bottom: 90px;
    cursor: pointer;
    z-index: 100;
}

.kefu-btn {
    max-width: 200px;
    height: 32px;
    background: linear-gradient(135deg, #12F4B3 0%, #01FEF0 100%);
    border-radius: 16px;
    border: 2px solid #101819;
    backdrop-filter: blur(10px);
    font-size: 16px;
    font-family: "bold";
    color: #0D1A1C;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0px 13px;
    bottom: -15px;
}

.kefu-phone {
    width: 120px;
    height: 122px;
    position: fixed;
    right: 30px;
    bottom: 90px;
    cursor: pointer;
    z-index: 100;
}

.kefu-en {
    padding: 0px 20px;
    margin: 0px 13px;
}

.kefu-phone-btn {
    /* min-width: 120px;
    height: 42px; */
    background: linear-gradient(135deg, #12F4B3 0%, #01FEF0 100%);
    border-radius: 21px;
    border: 2.5px solid #101819;
    backdrop-filter: blur(13.33333333333333px);
    font-size: 22px;
    font-family: "bold";
    color: #0D1A1C;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 8px 16px;
    bottom: 0px;
}
</style>