<template>
    <div class="about">
        <div style="height: 916px;padding-top: 50px;">
            <div class="about-c1">
                <div class="about-title">{{ $t("about.1") }}</div>
                <div class="about-t1">{{ $t("about.2") }}</div>
                <div class="about-c2-c1" v-for="(item, index) in textList" :key="index">
                    <div style="display: flex;align-items: center;">
                        <div class="about-c2-c1-line"></div><span class="about-c2-c1-t1">{{ $t(item.title) }}</span>
                    </div>
                    <div style="margin-top: 14px;display: inline-block;line-height: 36px;font-size: 18px;" v-html="Config.turnStar($t(item.text))"></div>
                </div>
            </div>
        </div>
        <div class="about-line1">
        </div>
    </div>
</template>
<script lang="ts" setup>
import Config from '@/data/config';
import { ref } from 'vue';

const textList = ref(
    [
        { title: "about.3", text: "about.4" },
        { title: "about.5", text: "about.6" },
    ]
)

</script>
<style scoped>
@import url("./index.css");
</style>