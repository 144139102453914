<template>
    <div class="pcIndex-card">
        <div class="pcIndex-card-icon">
            <img :src="props.src" width="114px" height="114px" />
        </div>
        <div class="pcIndex-card-title">{{ props.title }}</div>
        <div class="pcIndex-card-t1">{{ props.t1+props.p }}</div>
        <div class="pcIndex-card-t2">{{ props.t2+" USDT" }}</div>
        <div class="pcIndex-card-t3">{{ props.t3 }}</div>


    </div>
</template>
<script setup lang="ts">
import { defineProps } from "vue"
const props = defineProps({
    src: { type: String, default: () => { return require("../img/card_img1.png") } },
    title: { type: String, default: "现货套利机器人" },
    t1: { type: String, default: "月收益8%, 复利年收益250%+" },
    t2: { type: String, default: "123,125.25 USDT" },
    t3: { type: String, default: "累计套利" },
    p: { type: String, default: "250%+" },

})
</script>
<style scoped>
.pcIndex-card {
    width: 570px;
    height: 394px;
    background-image: url("../img/card_bg.png");
    background-size: 100% 100%;
    position: relative;
    margin-right: 60px;
}

.pcIndex-card-icon {
    position: absolute;
    width: 114px;
    height: 114px;
    left: 228px;
    top: -57px;
}

.pcIndex-card-title {
    font-family: "bold";
    font-size: 28px;
    line-height: 41px;
    color: #DEDEDE;
    width: 100%;
    left: 0px;
    text-align: center;
    position: absolute;
    top: 92px;
}

.pcIndex-card-t1 {
    font-family: "medium";
    font-size: 20px;
    line-height: 29px;
    color: #DEDEDE;
    width: 100%;
    left: 0px;
    text-align: center;
    position: absolute;
    top: 146px;
}

.pcIndex-card-t2 {
    font-family: "medium";
    font-size: 30px;
    line-height: 45px;
    color: #01FEF0;
    width: 100%;
    left: 0px;
    text-align: center;
    position: absolute;
    top: 249px;
}

.pcIndex-card-t3 {
    font-family: "medium";
    font-size: 20px;
    line-height: 29px;
    color: rgba(222, 222, 222,0.5);
    width: 100%;
    left: 0px;
    text-align: center;
    position: absolute;
    bottom: 57px;
}
</style>