import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../img/h5_icon_more.png'
import _imports_1 from '../img/h5_img_spot_graph.png'


const _hoisted_1 = { class: "mindex-card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mindex-card-t1" }
const _hoisted_4 = { class: "mindex-card-t2" }
const _hoisted_5 = { class: "mindex-card-c1" }
const _hoisted_6 = { class: "mindex-card-t3" }
const _hoisted_7 = { class: "mindex-card-t4" }

import Config from "@/data/config";


export default /*@__PURE__*/_defineComponent({
  __name: 'card',
  props: {
    src: { type: String, default: () => { return require("../img/h5_icon_spot.png") } },
    title: { type: String, default: "现货套利机器人" },
    t1: { type: String, default: "月收益8%, 复利年收益250%+" },
    t2: { type: String, default: "123,125.25 usdt" },
    t3: { type: String, default: "累计套利" },
    p: { type: String, default: "250%+" },
    index: { type: Number, default: 0 }

},
  setup(__props) {

const props = __props
function clickEvent() {
    Config.Router.push({
        path: "/mod",
        query: { mod: props.index }
    })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: props.src,
      class: "mindex-card-img1"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(props.title), 1),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString(props.t1), 1),
      _createElementVNode("span", null, _toDisplayString(props.p), 1)
    ]),
    _createElementVNode("img", {
      src: _imports_0,
      class: "mindex-card-img2",
      onClick: clickEvent
    }),
    _createElementVNode("div", _hoisted_5, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mindex-card-c1-bg" }, null, -1)),
      _createElementVNode("div", _hoisted_6, _toDisplayString(props.t3), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(props.t2 + " USDT"), 1),
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_1,
        class: "mindex-card-img3"
      }, null, -1))
    ])
  ]))
}
}

})