<template>
  <TopBar v-if="configStore.isPC"></TopBar>
  <router-view />
  <kefu></kefu>
  <BottomBar v-if="configStore.isPC"></BottomBar>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router"
import TopBar from "@/components/TopBar/index.vue"

import BottomBar from "@/components/BottomBar/index.vue"
import kefu from "@/components/kefu/index.vue"
import { onMounted } from "vue"
import Config from "@/data/config"
import useConfigStore from "./store/config"
import { useLangStore } from "./store/lang"
const configStore = useConfigStore()
Config.getScale()
onMounted(() => {
  const lang = window.localStorage.getItem("lang")
  if (lang) {
    useLangStore().nowLang = lang == "zh" ? 0 : lang == "en" ? 2 : 1
  }
  Config.Router = useRouter()
  windowReize()
  Config.Router.beforeEach((to, from, next) => {
    if (to.path == "/friends") configStore.topMenuActive = "3";
    if (to.path == "/mod") configStore.topMenuActive = "2";
    if (to.path == "/about") configStore.topMenuActive = "4";
    next()
  })
})
window.onresize = () => {
  windowReize()
}
function windowReize() {
  const a = Config.judge_m_or_p()
  configStore.isPC = a == "m" ? false : true
  console.log(configStore.isPC, "  ispc");
  let scale = Config.getScale()
  document.body.style.width = configStore.isPC ? "1920px" : "750px";

  // // document.body.style.height = height + "px";
  // // 设置body缩放

  // document.body.style.transform = `scale(${scale})`;
  // document.body.style.transformOrigin = '0 0';
  document.body.style.zoom = scale + ""
}
</script>
<style>
@font-face {
  font-family: 'default';
  src: url("@/assets/font/Regular.otf");
}

@font-face {
  font-family: 'medium';
  src: url("@/assets/font/Medium.otf");
}

@font-face {
  font-family: 'bold';
  src: url("@/assets/font/Bold.otf");
}

@font-face {
  font-family: 'heavy';
  src: url("@/assets/font/Heavy.otf");
}

@font-face {
  font-family: 'number';
  src: url("@/assets/font/DINAlternateBold.ttf");
}

body,
html {
  padding: 0px;
  margin: 0px;
  font-family: "default";
}

html {
  /* max-height: calc(100vh); */
  overflow-y: scroll;
  background-color: #151517;
}

body {
  position: relative;
  min-height: calc(100vh);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.textStar {
  display: inline-block;
  position: absolute;
  top: 3px;
}

.c-c {
  position: relative;
}

.c-c-t {
  display: inline-block;
  width: calc(100%);
  position: relative;
  font-size: 18px;
  line-height: 36px;
  left: 19px;
}

@media screen and (max-width: 750px) {
  .textStar {
    display: inline-block;
    position: absolute;
    top: 6px;
  }

  .c-c {
    position: relative;
  }

  .c-c-t {
    display: inline-block;
    width: calc(100%);
    position: relative;
    font-size: 28px;
    line-height: 60px;
    left: 19px;
  }
}
</style>
