<template>
    <div class="topBar">
        <img src="@/assets/topBar/logo.png" style="width: 113px;height: 20px;margin-left: 360px;" class="topBar-img" />
        <div style="margin-right: 360px;display: inline-flex;align-items: center;">
            <div>
                <div class="topBar-menuItem" v-for="(item, index) in menuList" :key="index"
                    @click="menuClick(item.key, item.path)"
                    :style="{ color: item.key === configStore.topMenuActive ? '#01FEF0' : '#DEDEDE' }">
                    {{ $t(item.name) }}
                    <div :class="'topBar-menuItem-Bar topBar-menuItem-Bar-' + index"
                        v-show="item.key == configStore.topMenuActive">
                    </div>
                </div>
            </div>
            <div class="topBar-langBtn" @click="chooseLang = !chooseLang">
                <img src="./img/h5_icon_language@2x.png" style="width: 14px;height: 14px;" />
                <span style="display: inline-block;margin: 0px 8px;">{{ langStore.langList[langStore.nowLang].name
                    }}</span>
                <img src="./img/pc_icon_pull@2x.png" v-if="chooseLang" style="width: 10px;height: 7px;" />
                <img src="./img/pc_icon_pull-1.png" v-else style="width: 10px;height: 7px;" />
                <div class="topBar-langDrop" v-show="chooseLang" @click.stop>
                    <span style="margin-top: 22px;" @click="selectLang(0)">繁体中文</span>
                    <div class="topBar-line"></div>
                    <span @click="selectLang(1)">简体中文</span>
                    <div class="topBar-line"></div>
                    <span @click="selectLang(2)">English</span>
                </div>
            </div>


        </div>
    </div>
</template>
<style scoped>
@import url("./index.css");
</style>
<script lang="ts" setup>
import Config from "@/data/config";
import i18n from "@/i18n";
import useConfigStore from "@/store/config";
import { useLangStore } from "@/store/lang";
import { ref } from "vue"
const langStore = useLangStore()
const configStore = useConfigStore()
const menuList = ref([
    { name: "topbar.1", path: "/", key: "1" },
    { name: "topbar.2", path: "/mod", key: "2" },
    { name: "topbar.3", path: "/friends", key: "3" },
    { name: "topbar.4", path: "/about", key: "4" },
])
const chooseLang = ref(false)
function selectLang(index: number) {
    langStore.nowLang = index
    i18n.global.locale.value = langStore.langList[index].code
    window.localStorage.setItem("lang", langStore.langList[index].code)
    chooseLang.value = false
}
function menuClick(key: string, path: string) {
    configStore.topMenuActive = key
    Config.Router.push(path)

}
function setBarPosition() {
    let div = document.getElementById("menuBar")
}
</script>